import React, {useState} from "react";

import { Pagination } from "react-bulma-components";
import { Box } from "react-bulma-components";
import { Button } from "react-bulma-components";
import { Form } from "react-bulma-components";
import { Columns } from "react-bulma-components";
import VehicleTotals from "./VehicleTotals";
import {useVehiclesData} from "../../../hooks/useVehiclesData";
import {
  getDataFresh,
  getVehiclesData, getVehicleStatusCount,
} from "../../../state/selectors";
import Spinner from "react-spinner-material";
import {useDispatch, useSelector} from "react-redux";
import {useVehicleSummaryData} from "../../../hooks/useVehicleSummaryData";
import {formatDate, getQueryString, onFileChange, readableDate} from "../../../utils";
import { Tabs } from "react-bulma-components";
import FilterHeading from "../../Invoicing/FilterHeading";
import ActiveHeadings from "./headings/ActiveHeadings";
import ActiveRow from "./rows/ActiveRow";
import OrderedRow from "./rows/OrderedRow";
import OrderedHeadings from "./headings/OrderedHeadings";
import ArrivingRow from "./rows/ArrivingRow";
import ArrivingHeadings from "./headings/ArrivingHeadings";
import SoldHeadings from "./headings/SoldHeadings";
import SoldRow from "./rows/SoldRow";
import ForSaleRow from "./rows/ForSaleRow";
import ForSaleHeadings from "./headings/ForSaleHeadings";
import {
  createSetDataFreshAction,
  createSetPartsDataAction,
  createSetVehiclesDataAction
} from "../../../actions/dataActions";
import {useVehicleStatusCountData} from "../../../hooks/useVehicleStatusCountData";
import StatusCount from "./StatusCount";
import SearchBar from "../../Utils/SearchBar";
import ProcessingSaleHeadings from "./headings/ProcessingSaleHeadings";
import ProcessingSaleRow from "./rows/ProcessingSaleRow";
import axios from "axios";
import {NotificationManager} from "react-notifications";
import {useCapTotalChangesData} from "../../../hooks/useCapTotalChangesData";
import CapTotals from "./CapTotals";

function VehiclesView({history, endpoint}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [vehicleStatus, setVehicleStatus] = useState("ACTIVE");
  const [searchText, setSearchText] = useState();
  const [filters, setFilters] = useState({});
  const [currentSearch, setCurrentSearch] = useState();
  const [activeTab, setTab] = useState("fleet");
  const [searchFresh, setSearchFresh] = useState(0);
  const [fileList, setFileList] = useState();


  const onFileSend = () => {
    if(fileList){
      const conf = {
        url: endpoint+"vehiclescap",
        method: "post",
        data: fileList[0]
      }
      axios(conf).then((res) => {
        dispatch(createSetDataFreshAction(dataFresh + 1));
        setFileList(undefined)
      }).catch(err => {
        NotificationManager.error("There was an error uploading the cap data");
      })
    }
    else {
      NotificationManager.warning("No File was selected for upload")
    }
  }



  let params = {page};

  if (currentSearch) {
    params.search = currentSearch;
  }
  if (searchFresh) {
    params.searchFresh = searchFresh
  }
  if (activeTab === 'fleet') {
    params.tab = 'fleet';
    if (vehicleStatus !== 'all') {
      params.vehicle_status = vehicleStatus;
    }
    if (vehicleStatus === 'ARRIVING') {
      params.vehicle_status = 'ORDERED';
      params.delivery_date_confirmed = true;
      params.order = 'delivery_date'
    }
    if (vehicleStatus === 'ORDERED') {
      params.order = 'month_due'
    }
  } else {
    params.tab = 'customer'
  }

  for (let filter in filters) {
    if (filters[filter][0] && filters[filter][0][filter]) {
      params[filter] = filters[filter][0][filter];
    }
  }

  const onVehicleUpdate = (index, newVehicle) => {
    let newState = { ...data };
    newState.results[index] = newVehicle;
    dispatch(createSetVehiclesDataAction(newState));
  };

  const onFinanceUpdate = (index, finance) => {
  }

  const onRowChange = (e, index, vehicle) => {
    let newVehicle = {...vehicle, [e.target.name]: e.target.value}
    onVehicleUpdate(index, newVehicle);
  }

  const onRowSave = (vehicle) => {
    const conf = {
      url: endpoint+"vehicles/"+vehicle.id,
      method: "put",
      data: vehicle
    }
    axios(conf).then(() => {
      dispatch(createSetDataFreshAction(dataFresh + 1))
    })
  }

  let dataFresh = useSelector(getDataFresh);
  const complete = useVehiclesData(params, dataFresh);
  const countLoaded = useVehicleStatusCountData({}, dataFresh);
  const summaryComplete = useVehicleSummaryData({}, dataFresh);
  const capLoaded = useCapTotalChangesData({}, dataFresh);


  const data = useSelector(getVehiclesData);
  const countData = useSelector(getVehicleStatusCount);

  const handleSearch = () => {
    setPage(1);
    setCurrentSearch(searchText);
    setSearchFresh(searchFresh+1);
  };

  const setNewVehicleStatus = (status) => {
    setPage(1)
    setVehicleStatus(status)
  }

  const clear = () => {
    setSearchText("");
    setCurrentSearch("");
    setPage(1);
  };

  if (!complete || !summaryComplete || !countLoaded) {
    return (
      <Box>
        <Spinner
          className="spinner-centre"
          size={120}
          spinnerColor={"#3273dc"}
          spinnerWidth={2}
        />
      </Box>
    );
  }

  document.title = "Vehicles";

  return (
    <div>
      <Box>
        <Columns>
          <Columns.Column>
            <Form.Label>Search for a Vehicle</Form.Label>
            <SearchBar
              handleSearch={handleSearch}
              clear={clear}
              setSearchText={setSearchText}
              searchText={searchText}
            />
          </Columns.Column>
          <Columns.Column>
            <Form.Field>
              <Form.Control>
                <Button
                  onClick={() => history.push("/editvehicle")}
                  color="success"
                  className="is-pulled-right"
                >
                  New Vehicle +
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
        <Columns>
          <Columns.Column>
            <Form.Label>Cap Upload</Form.Label>
            <Form.Field className={"has-addons"}>
              <Form.Control>
                <Form.InputFile
                  value={fileList}
                  onChange={ e => onFileChange(e, fileList, setFileList)}
                />
              </Form.Control>
              <Form.Control>
                <Button
                  color={"success"}
                  onClick={onFileSend}
                  disabled={!(!!fileList)}
                >
                  Upload
                </Button>
              </Form.Control>
            </Form.Field>
          </Columns.Column>
        </Columns>
      </Box>
      {activeTab === "fleet" && vehicleStatus === 'ACTIVE' && summaryComplete && <VehicleTotals/>}
      {capLoaded && <CapTotals />}
      <StatusCount data={countData}/>
      <Box>
        <h1 className="title">Vehicles</h1>
        <Tabs type="boxed" fullwidth align="centered">
          <Tabs.Tab active={activeTab === "fleet"} onClick={() => {setTab("fleet"); setNewVehicleStatus('all');}}>
            Fleet
          </Tabs.Tab>
          <Tabs.Tab
            active={activeTab === "customer"}
            onClick={() => {setTab("customer"); setNewVehicleStatus('all');}}
          >
            External Customer Vehicles
          </Tabs.Tab>
        </Tabs>
        {activeTab != "customer" &&
        <Tabs type="toggle" align="centered">
          <Tabs.Tab
            active={vehicleStatus === "EQUIPMENT"}
            onClick={() => setNewVehicleStatus("EQUIPMENT")}
          >
            Equipment
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "all"}
            onClick={() => setNewVehicleStatus("all")}
          >
            All
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "ORDERED"}
            onClick={() => setNewVehicleStatus("ORDERED")}
          >
            Ordered
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "ARRIVING"}
            onClick={() => setNewVehicleStatus("ARRIVING")}
          >
            Arriving
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "ACTIVE"}
            onClick={() => setNewVehicleStatus("ACTIVE")}
          >
            Active
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "FORSALE"}
            onClick={() => setNewVehicleStatus("FORSALE")}
          >
            For Sale
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "PROCESSING"}
            onClick={() => setNewVehicleStatus("PROCESSING")}
          >
            Processing Sale
          </Tabs.Tab>
          <Tabs.Tab
            active={vehicleStatus === "SOLD"}
            onClick={() => setNewVehicleStatus("SOLD")}
          >
            Sold
          </Tabs.Tab>
        </Tabs>
        }
        <table className="table is-hoverable is-fullwidth is-striped">
          <thead>
          {(vehicleStatus === 'ACTIVE' || vehicleStatus === 'all' || vehicleStatus === "EQUIPMENT") && (
            <ActiveHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          )}
          {vehicleStatus === 'ORDERED' &&
            <OrderedHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          }
          {vehicleStatus === 'ARRIVING' &&
            <ArrivingHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          }
          {vehicleStatus === 'FORSALE' && (
            <ForSaleHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          )}
          {vehicleStatus === 'PROCESSING' &&
            <ProcessingSaleHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          }
          {vehicleStatus === 'SOLD' &&
            <SoldHeadings data={data} vehicleStatus={vehicleStatus} activeTab={activeTab} filters={filters} setFilters={setFilters} setPage={setPage}/>
          }
          </thead>
          <tbody>
          {data.results.map((value, index) => {
              let finance;
              if (value.finance_agreements.length) {
                const agreements = value.finance_agreements.sort((a,b) => new Date(a.start_date) - new Date(b.start_date))
                finance = agreements[0]
              } else {
                finance = {
                  estimated_value: 0,
                  remaining_balance: 0,
                  sold_price: 0,
                  sold_date: "",
                  sale_comments: ""
                }
              }
              if (vehicleStatus === 'ACTIVE' || vehicleStatus === 'all' || vehicleStatus === "EQUIPMENT") {
                return <ActiveRow value={value} finance={finance} activeTab={activeTab}/>
              }
              if (vehicleStatus === 'ORDERED') {
                return <OrderedRow index={index} onVehicleUpdate={onVehicleUpdate} endpoint={endpoint} value={value} finance={finance} activeTab={activeTab}/>
              }
              if (vehicleStatus === 'ARRIVING') {
                return <ArrivingRow index={index} onVehicleUpdate={onVehicleUpdate} endpoint={endpoint} value={value} finance={finance} activeTab={activeTab}/>
              }
              if (vehicleStatus === 'FORSALE') {
                return <ForSaleRow index={index} value={value} finance={finance} activeTab={activeTab} onSave={onRowSave} onChange={onRowChange}/>
              }
              if (vehicleStatus === 'PROCESSING') {
                return <ProcessingSaleRow value={value} finance={finance} activeTab={activeTab}/>
              }
              if (vehicleStatus === 'SOLD') {
                return <SoldRow value={value} finance={finance} activeTab={activeTab}/>

              }
            }

            )
          }
          </tbody>
        </table>
        <Pagination
          showFirstLast={true}
          onChange={setPage}
          current={page}
          total={Math.ceil(data.count / 25)}
        ></Pagination>
      </Box>
    </div>
  );
}

export default VehiclesView;
