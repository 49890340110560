import React from "react";
import {Box, Columns, Heading} from "react-bulma-components";
import {getCapTotalsData} from "../../../state/selectors";
import {useSelector} from "react-redux";



export default function CapTotals(){
    let data = useSelector(getCapTotalsData);



    return (
        <Box>
            <Columns>
                {/*{Object.keys(data).map((item) => (*/}
                {/*    // Item is the vehicle model name*/}
                {/*    <div>*/}
                {/*        <Columns.Column>*/}
                {/*            {item+" CAP BELOW: £"+data[item]["cap_below"]}*/}
                {/*        </Columns.Column>*/}
                {/*        <Columns.Column>*/}
                {/*            {item+" CAP AVERAGE: £"+data[item]["cap_average"]}*/}
                {/*        </Columns.Column>*/}
                {/*        <Columns.Column>*/}
                {/*            {item+" CAP CLEAN: £"+data[item]["cap_clean"]}*/}
                {/*        </Columns.Column>*/}
                {/*        <Columns.Column>*/}
                {/*            {item+" CAP RETAIL: £"+data[item]["cap_retail"]}*/}
                {/*        </Columns.Column>*/}
                {/*    </div>*/}
                {/*))}*/}
                <Columns.Column>
                    {"Total Difference in Cap Below: £"+data['cap_below']}
                </Columns.Column>
                <Columns.Column>
                    {"Total Difference in Cap Average: £"+data['cap_average']}
                </Columns.Column>
                <Columns.Column>
                    {"Total Difference in Cap Clean: £"+data['cap_clean']}
                </Columns.Column>
                <Columns.Column>
                    {"Total Difference in Cap Retail: £"+data['cap_retail']}
                </Columns.Column>
            </Columns>
        </Box>
    )



}